import React from "react"
import { Layout, Iframe } from "../components"

const Meeting = () => (
  <Layout>
    <Iframe id={process.env.GATSBY_SCHEDULE_MEETING_FORM_ID} />
  </Layout>
)

export default Meeting
